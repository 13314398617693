<template>
  <div class="my-5 mr-5">
    <div v-if="feedsByStoreId(store.id).length">
      <span>
        You have already added a product feed.<br />
        To add more feeds
      </span>
      <bid-link
        :router-link="true"
        href-link="campaign:feed"
      >
        <template #text> click here </template>
        <template #icon>
          <ic-link />
        </template>
      </bid-link>
      <div class="flex flex-wrap mt-5 gap-3">
        <the-button
          @click.stop="$emit('updateStep', { step, index, incremental: -1 })"
          inner-class="w-40"
          secondary
        >
          Back
        </the-button>
        <the-button
          @click.stop="$emit('updateStep', { step, index })"
          inner-class="w-40"
          primary
        >
          Next
        </the-button>
      </div>
    </div>
    <div v-else>
      <feed-form
        @submitted="onSubmitted({ step, index })"
        @skipped="onSkipped({ step, index })"
        :load-feeds="false"
      >
        <template #action-btn="{ countryId, loading, submit }">
          <div class="flex flex-wrap mt-5 gap-3">
            <the-button
              @click.stop="$emit('updateStep', { step, index, incremental: -1 })"
              inner-class="w-40"
              secondary
            >
              Back
            </the-button>

            <the-button
              v-if="!step.completed && checkIfLastStep"
              @click.stop="submit()"
              :disabled="loading || !countryId"
              id="feed_next"
              inner-class="w-40"
              primary
            >
              Complete
              <template #icon>
                <ic-check :size="20" />
              </template>
            </the-button>
            <the-button
              v-else
              @click.stop="submit()"
              :disabled="loading || !countryId"
              id="feed_next"
              inner-class="w-40"
              primary
            >
              Next
            </the-button>
            <span
              class="text-bb-mid-grey cursor-pointer my-auto ml-auto"
              @click.stop="$emit('updateStep', { step, index })"
            >
              skip this step
            </span>
          </div>
        </template>
      </feed-form>
    </div>
  </div>
</template>

<script>
import FeedForm from '@/components/feed/FeedForm'
import { mapGetters } from 'vuex'
import BidLink from '@/components/base/BidLink'
import IcLink from 'vue-material-design-icons/OpenInNew'
import IcCheck from 'vue-material-design-icons/Check'

export default {
  name: 'ProductFeed',
  components: { BidLink, FeedForm, IcLink, IcCheck },
  props: {
    step: {
      type: Object,
    },
    index: {
      type: Number,
    },
    checkIfLastStep: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters({
      store: 'store/selectedStore',
      feedsByStoreId: 'feed/getFeedsByStoreId',
    }),
  },
  methods: {
    onSubmitted({ step, index }) {
      this.$gtm.trackEvent({
        event: 'onboarding_product_feed',
        event_source: 'onboarding',
        project: 'console',
      })
      this.$emit('updateStep', { step, index })
    },
    onSkipped({ step, index }) {
      this.$gtm.trackEvent({
        event: 'onboarding_product_feed_skipped',
        event_source: 'onboarding',
        project: 'console',
      })
      this.$emit('updateStep', { step, index })
    },
  },
}
</script>
